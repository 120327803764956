import { defineStore } from "pinia";
// pinia状态机
export const useRoles = defineStore("roles", {
  // 存储数据
  state: () => ({
    total: 0,
    // 菜单目录数据
    menuItems: [
      {
        title: "多门店配置",
        id: "1",
        path: "/",
        name: "home",
        component: "home/HomeView",
        label: "多门店配置",
        icon: "Shop",
        permissions: ["0", "1", "2"],
        meta: {
          breadcrumb: "首页",
        },
        children: [
          {
            title: "首页",
            id: "1-1",
            path: "/storeinformation",
            name: "storeinformation",
            component: "shop/StoreInformation/StoreInformation",
            permissions: ["0", "1", "2"],
            label: "首页",
            meta: {
              breadcrumb: "门店信息管理",
            },
          },
          {
            title: "硬件设备配置",
            id: "1-3",
            path: "/hardwaredevices",
            component: "shop/HardwareDevices/HardwareDevices",
            name: "hardwaredevices",
            permissions: ["0", "1", "2"],
            label: "硬件设备配置",
            meta: {
              breadcrumb: "硬件设备配置",
            },
          },
          {
            title: "门店员工管理",
            id: "1-4",
            path: "/storeemployees",
            component: "shop/StoreEmployees/StoreEmployees",
            name: "storeemployees",
            permissions: ["0", "1", "2"],
            label: "门店员工管理",
            meta: {
              breadcrumb: "门店员工管理",
            },
          },
          {
            title: "门店统计信息",
            id: "1-5",
            path: "/storestatistics",
            component: "shop/StoreStatistics/StoreStatistics",
            name: "storestatistics",
            permissions: ["1", "2"],
            label: "门店统计信息",
            meta: {
              breadcrumb: "门店统计信息",
            },
          },
          {
            title: "门店状态管理",
            id: "1-7",
            path: "/storestatus",
            component: "shop/StoreStatus/StoreStatus",
            name: "storestatus",
            permissions: ["1", "2"],
            label: "门店状态管理",
            meta: {
              breadcrumb: "门店状态管理",
            },
          },
        ],
      },
      {
        title: "订单管理",
        id: "2",
        path: "/order",
        name: "order",
        component: "home/HomeView",
        icon: "ShoppingCart",
        permissions: ["0", "1", "2"],
        label: "订单管理",
        meta: {
          breadcrumb: "订单管理",
        },
        children: [
          {
            title: "订单列表",
            id: "2-1",
            path: "/orderlist",
            component: "order/OrderList/OrderList",
            permissions: ["0", "1", "2"],
            label: "订单列表",
            meta: {
              breadcrumb: "订单列表",
            },
          },
          {
            title: "退款记录",
            id: "2-3",
            path: "/paymentmanagement",
            component: "order/PaymentManagement/PaymentManagement",
            permissions: ["0", "1", "2"],
            label: "退款记录",
            meta: {
              breadcrumb: "退款记录",
            },
          },
          {
            title: "退款申请",
            id: "2-4",
            path: "/refundmanagement",
            component: "order/RefundManagement/RefundManagement",
            permissions: ["0", "1", "2"],
            label: "退款申请",
            meta: {
              breadcrumb: "退款申请",
            },
          },
          {
            title: "订单状态管理",
            id: "2-5",
            path: "/orderstatus",
            component: "order/OrderStatus/OrderStatus",
            permissions: ["0", "1", "2"],
            label: "订单状态管理",
            meta: {
              breadcrumb: "订单状态管理",
            },
          },
          {
            title: "订单操作历史",
            id: "2-6",
            path: "/orderhistory",
            component: "order/OrderHistory/OrderHistory",
            permissions: ["0", "1", "2"],
            label: "订单操作历史",
            meta: {
              breadcrumb: "订单操作历史",
            },
          },
          {
            title: "统计与报表",
            id: "2-7",
            path: "/orderreports",
            component: "order/OrderReports/OrderReports",
            permissions: ["0", "1", "2"],
            label: "统计与报表",
            meta: {
              breadcrumb: "统计与报表",
            },
          },
        ],
      },
      {
        title: "用户管理",
        id: "3",
        path: "/user",
        component: "home/HomeView",
        permissions: ["1", "2"],
        label: "用户管理",
        meta: {
          breadcrumb: "用户管理",
        },
        icon: "Avatar",
        children: [
          {
            title: "用户列表",
            id: "3-1",
            path: "/userlist",
            component: "user/UserList/UserList",
            permissions: ["1", "2"],
            label: "用户列表",
            meta: {
              breadcrumb: "用户列表",
            },
          },
          {
            title: "用户信息",
            id: "3-2",
            path: "/userinformation",
            component: "user/UserInformation/UserInformation",
            permissions: ["1", "2"],
            label: "用户信息",
            meta: {
              breadcrumb: "用户信息",
            },
          },
          {
            title: "权限管理",
            id: "3-3",
            path: "/userpermissions",
            component: "user/UserPermissions/UserPermissions",
            permissions: ["1", "2"],
            label: "权限管理",
            meta: {
              breadcrumb: "权限管理",
            },
          },
          {
            title: "角色管理",
            id: "3-4",
            path: "/rolemanager",
            component: "user/RoleManager/RoleManager",
            permissions: ["1", "2"],
            label: "角色管理",
            meta: {
              breadcrumb: "角色管理",
            },
          },
          {
            title: "登录日志",
            id: "3-5",
            path: "/loginlog",
            component: "user/LoginLog/LoginLog",
            permissions: ["1", "2"],
            label: "登录日志",
            meta: {
              breadcrumb: "登录日志",
            },
          },
        ],
      },
      {
        title: "Socket管理",
        id: "4",
        path: "/socket",
        component: "home/HomeView",
        permissions: ["1", "2"],
        label: "Socket管理",
        meta: {
          breadcrumb: "Socket管理",
        },
        icon: "Link",
        children: [
          {
            title: "Socket连接管理",
            id: "4-1",
            path: "/connectionmanagement",
            component: "socket/ConnectionManagement/ConnectionManagement",
            permissions: ["1", "2"],
            label: "Socket连接管理",
            meta: {
              breadcrumb: "Socket连接管理",
            },
          },
          {
            title: "协议配置与解析",
            id: "4-2",
            path: "/protocolconfiguration",
            component: "socket/ProtocolConfiguration/ProtocolConfiguration",
            permissions: ["1", "2"],
            label: "协议配置与解析",
            meta: {
              breadcrumb: "协议配置与解析",
            },
          },
          {
            title: "命令规则管理",
            id: "4-3",
            path: "/commandRule",
            component: "socket/CommandRule/CommandRule",
            permissions: ["1", "2"],
            label: "命令规则管理",
            meta: {
              breadcrumb: "命令规则管理",
            },
          },
          {
            title: "通信日志记录",
            id: "4-4",
            path: "/communicationlogs",
            component: "socket/CommunicationLogs/CommunicationLogs",
            permissions: ["1", "2"],
            label: "通信日志记录",
            meta: {
              breadcrumb: "通信日志记录",
            },
          },
        ],
      },
      {
        title: "商品管理",
        id: "5",
        path: "/goods",
        component: "home/HomeView",
        permissions: ["0", "1", "2"],
        label: "商品管理",
        meta: {
          breadcrumb: "商品管理",
        },
        icon: "Goods",
        children: [
          {
            title: "商品列表",
            id: "5-1",
            path: "/goodlist",
            component: "goods/GoodList/GoodList",
            permissions: ["0", "1", "2"],
            label: "商品列表",
            meta: {
              breadcrumb: "商品列表",
            },
          },
          {
            title: "商品详情",
            id: "5-2",
            path: "/gooddetails",
            component: "goods/GoodDetails/GoodDetails",
            permissions: ["0", "1", "2"],
            label: "商品详情",
            meta: {
              breadcrumb: "商品详情",
            },
          },
          {
            title: "添加与删除",
            id: "5-3",
            path: "/goodadd",
            component: "goods/GoodAdd/GoodAdd",
            permissions: ["0", "1", "2"],
            label: "添加与删除",
            meta: {
              breadcrumb: "添加与删除",
            },
          },
          {
            title: "修改商品",
            id: "5-4",
            path: "/goodupdate",
            component: "goods/GoodUpdate/GoodUpdate",
            permissions: ["0", "1", "2"],
            label: "修改商品",
            meta: {
              breadcrumb: "修改商品",
            },
          },
          {
            title: "分类管理",
            id: "5-5",
            path: "/goodclassification",
            component: "goods/GoodClassification/GoodClassification",
            permissions: ["0", "1", "2"],
            label: "分类管理",
            meta: {
              breadcrumb: "分类管理",
            },
          },
          {
            title: "属性管理",
            id: "5-6",
            path: "/goodattribute",
            component: "goods/GoodAttribute/GoodAttribute",
            permissions: ["0", "1", "2"],
            label: "属性管理",
            meta: {
              breadcrumb: "属性管理",
            },
          },
          {
            title: "商品搜索与推荐",
            id: "5-7",
            path: "/goodsearch",
            component: "goods/GoodSearch/GoodSearch",
            permissions: ["0", "1", "2"],
            label: "商品搜索与推荐",
            meta: {
              breadcrumb: "商品搜索与推荐",
            },
          },
        ],
      },
      {
        title: "团购管理",
        id: "6",
        path: "/coupon",
        component: "home/HomeView",
        permissions: ["1", "2"],
        label: "团购管理",
        meta: {
          breadcrumb: "团购管理",
        },
        icon: "Ticket",
        children: [
          {
            title: "团购发放",
            id: "6-1",
            path: "/coupongrant",
            component: "coupon/CouponGrant/CouponGrant",
            permissions: ["1", "2"],
            label: "团购发放",
            meta: {
              breadcrumb: "团购发放",
            },
          },
          {
            title: "团购领取记录",
            id: "6-2",
            path: "/couponcollection",
            component: "coupon/CouponCollection/CouponCollection",
            permissions: ["1", "2"],
            label: "团购领取记录",
            meta: {
              breadcrumb: "团购领取记录",
            },
          },
          {
            title: "团购使用记录",
            id: "6-3",
            path: "/usagehistory",
            component: "coupon/UsageHistory/UsageHistory",
            permissions: ["1", "2"],
            label: "团购使用记录",
            meta: {
              breadcrumb: "团购使用记录",
            },
          },
          {
            title: "团购统计",
            id: "6-4",
            path: "/CouponStatistics",
            component: "coupon/CouponStatistics/CouponStatistics",
            permissions: ["1", "2"],
            label: "团购统计",
            meta: {
              breadcrumb: "团购统计",
            },
          },
          {
            title: "团购状态管理",
            id: "6-5",
            path: "/couponstatus",
            component: "coupon/CouponStatus/CouponStatus",
            permissions: ["1", "2"],
            label: "团购状态管理",
            meta: {
              breadcrumb: "团购状态管理",
            },
          },
          {
            title: "团购失效提醒",
            id: "6-9",
            path: "/couponreminder",
            component: "coupon/CouponReminder/CouponReminder",
            permissions: ["1", "2"],
            label: "团购失效提醒",
            meta: {
              breadcrumb: "团购失效提醒",
            },
          },
          {
            title: "审核机制",
            id: "6-10",
            path: "/auditmechanism",
            component: "coupon/AuditMechanism/AuditMechanism",
            permissions: ["1", "2"],
            label: "审核机制",
            meta: {
              breadcrumb: "审核机制",
            },
          },
          {
            title: "退回与作废",
            id: "6-11",
            path: "/returncancel",
            component: "coupon/ReturnCancel/ReturnCancel",
            permissions: ["1", "2"],
            label: "退回与作废",
            meta: {
              breadcrumb: "退回与作废",
            },
          },
        ],
      },
      {
        title: "系统设置",
        id: "7",
        path: "/configure",
        component: "home/HomeView",
        permissions: ["1", "2"],
        label: "系统设置",
        meta: {
          breadcrumb: "系统设置",
        },
        icon: "FolderOpened",
        children: [
          {
            title: "配置短信发信服务",
            id: "7-2",
            path: "/configureSMS",
            component: "configure/ConfigureSMS/ConfigureSMS",
            permissions: ["1", "2"],
            label: "配置短信发信服务",
            meta: {
              breadcrumb: "配置短信发信服务",
            },
          },
          {
            title: "支付配置",
            id: "7-3",
            path: "/configurePayment",
            component: "configure/ConfigurePayment/ConfigurePayment",
            permissions: ["1", "2"],
            label: "支付配置",
            meta: {
              breadcrumb: "支付配置",
            },
          },
          {
            title: "RSA数字签名",
            id: "7-4",
            path: "/RSASymmetricEncryption",
            component:
              "configure/RSASymmetricEncryption/RSASymmetricEncryption",
            permissions: ["1", "2"],
            label: "RSA数字签名",
            meta: {
              breadcrumb: "RSA数字签名",
            },
          },
          {
            title: "小程序配置",
            id: "7-5",
            path: "/configureAppId",
            component: "configure/configureAppId/configureAppId",
            permissions: ["1", "2"],
            label: "小程序配置",
            meta: {
              breadcrumb: "小程序配置",
            },
          },
          {
            title: "腾讯地图配置",
            id: "7-6",
            path: "/configureMap",
            component: "configure/configureMap/configureMap",
            permissions: ["1", "2"],
            label: "腾讯地图配置",
            meta: {
              breadcrumb: "腾讯地图配置",
            },
          },
          {
            title: "开店审核",
            id: "7-7",
            path: "/configureExamine",
            component: "configure/configureExamine/configureExamine",
            permissions: ["1", "2"],
            label: "开店审核",
            meta: {
              breadcrumb: "开店审核",
            },
          },
          {
            title: "运营信息",
            id: "7-8",
            path: "/configureOperate",
            component: "configure/configureOperate/configureOperate",
            permissions: ["1", "2"],
            label: "运营信息",
            meta: {
              breadcrumb: "运营信息",
            },
          },
        ],
      },
      {
        title: "派单管理",
        id: "8",
        path: "/distribution",
        component: "home/HomeView",
        permissions: ["1", "2"],
        label: "派单管理",
        meta: {
          breadcrumb: "派单管理",
        },
        icon: "Odometer",
        children: [
          {
            title: "派单大厅",
            id: "8-1",
            path: "/lobby",
            component: "distribution/lobby/lobby",
            permissions: ["1", "2"],
            label: "派单大厅",
            meta: {
              breadcrumb: "派单大厅",
            },
          },
          {
            title: "骑手信息",
            id: "8-2",
            path: "/rider",
            component: "distribution/rider/rider",
            permissions: ["1", "2"],
            label: "骑手信息",
            meta: {
              breadcrumb: "骑手信息",
            },
          },
          {
            title: "派单记录",
            id: "8-3",
            path: "/dispatch",
            component: "distribution/dispatch/dispatch",
            permissions: ["1", "2"],
            label: "派单记录",
            meta: {
              breadcrumb: "派单记录",
            },
          },
          {
            title: "骑手返佣",
            id: "8-4",
            path: "/rebate",
            component: "distribution/rebate/rebate",
            permissions: ["1", "2"],
            label: "骑手返佣",
            meta: {
              breadcrumb: "骑手返佣",
            },
          },
          {
            title: "提现申请",
            id: "8-5",
            path: "/application",
            component: "distribution/application/application",
            permissions: ["1", "2"],
            label: "提现申请",
            meta: {
              breadcrumb: "提现申请",
            },
          },
        ],
      },
    ], // 菜单数据存储
  }),
  // 计算store中状态的新数据，类似计算属性
  getters: {},
  //Actions 执行异步/同步操作或修改状态的方法。
  actions: {},
  // 同步地修改状态
  mutations: {},
}
);
