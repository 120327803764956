import { createPinia } from "pinia";

import { useUserInfo } from "./userInfo";
import { useRoles } from "./roles";
//引入持久化插件
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

//创建store实例
const store = createPinia();
store.use(piniaPluginPersistedstate);

export { useUserInfo, useRoles };
export default store;
