import { defineStore } from "pinia";

export const useUserInfo = defineStore("userInfo", {
  state: () => {
    return {
      userInfo: {
        token: "",
        cid: "",
        nickName: "",
        permissionid: "",
        phone: "",
      },
      personLog: {
        ip: "",
        os: "",
        ccu: "",
      },
      publickey: "",
    };
  },
  actions: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
  },
  //配置缓存
  persist: true,
});
