import { createApp } from "vue";
import App from "./App.vue";

// 全局样式
import "@/utils/style.css";
// ElementPlus
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
// 添加element-ui的icon
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// 路由
import router from "./router";
// element中文包
import locale from "element-plus/dist/locale/zh-cn.mjs";
//引入store
import store from "./store";

import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
// import hls from "videojs-contrib-hls"; //引入才能播放m3u8文件
const app = createApp(App);

// app.use(hls);
app.use(VueVideoPlayer);

// ElementPlus
app.use(ElementPlus, { locale });

// 添加element-ui的icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(router).use(store).mount("#app");
