import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from "../views/home/HomeView.vue";
// pinia状态机
import { useRoles } from "@/store/roles";
import { useUserInfo } from "@/store";
// import { ElMessage } from "element-plus";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/LoginPage.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    component: () => import("../views/404/NotFound.vue"),
  },
];

const router = createRouter({
  // 路由
  routes,
  // 路由模式
  history: createWebHashHistory(),
});

//递归添加数据
const mapRoutes = (data) => {
  return data.reduce((prev, item) => {
    if (item.children) {
      return [...prev, ...mapRoutes(item.children)];
    }
    return [
      ...prev,
      {
        path: item.path,
        component: () => import("../views/" + item.component + ".vue"),
      },
    ];
  }, []);
};

let isLoaded = false;
router.beforeEach((to, from, next) => {
  // 全局网页标题
  document.title = "上海创泓度网络科技有限公司";

  // 在你想要使用菜单数据的地方获取 useRoles 实例
  const rolesStore = useRoles();
  // 访问菜单数据
  const myData = rolesStore.menuItems;

  if (myData.length && !isLoaded) {
    // 动态添加路由
    myData.forEach((routeItem) => {
      router.addRoute({
        path: routeItem.path,
        component: () => import(`../views/${routeItem.component}.vue`),
        children: mapRoutes(routeItem.children || []),
      });
    });

    isLoaded = true;

    return next({
      ...to,
      replace: true,
    });
  }

  const whiteList = ["/login", "/:pathMatch(.*)"];
  const store = useUserInfo();
  const token = store.userInfo.token;
  if (whiteList.includes(to.path) || (token && to.path !== "/login")) {
    return next();
  } else {
    return next(token ? "/" : "/login");
  }
  // return next();
});

export default router;
